import React from 'react';
import Bubble from '../components/Bubble';
import ModifiedBubble from '../components/ModifiedBubble';
import { STORE } from '../store';
import SingleSelect from '../components/SingleSelect';
import MultiSelect from '../components/MultiSelect';
import RangeSlider from '../components/Range';
import ImageCard from '../components/Image';
import DateTime from '../components/DateTime';
import Contact from '../components/Contact';
import Video from '../components/Video';
import Rating from '../components/Rating';
import Comment from '../components/Comment';
import PushNotification from '../components/PushNotification';
import { days, intervals } from './constants';
import File from '../components/File';
import { scroll } from './shared';

const state = STORE.getState().design;

function renderComponent(question, index) {
  scroll();
  switch (question.type.toUpperCase()) {
    case 'STATEMENT':
      return (
        <Bubble
          mid={question.mid}
          key={index}
          text={question.label}
          position={question.position}
          avatar={state.avatar}
          shouldFeedbackVisible={question.shouldFeedbackVisible}
          hasClickedThumbsUp={question.hasClickedThumbsUp}
          hasClickedThumbsDown={question.hasClickedThumbsDown}
        />
      );
    case 'NAME':
      return (
        <Bubble
          key={index}
          text={question.label}
          position={question.position}
          avatar={state.avatar}
        />
      );
    case 'QUESTION':
      return (
        <Bubble
          key={index}
          text={question.label}
          position={question.position}
          avatar={state.avatar}
        />
      );
    case 'EMAIL':
      return (
        <Bubble
          key={index}
          text={question.label}
          position={question.position}
          avatar={state.avatar}
        />
      );
    case 'PHONE':
      return (
        <Bubble
          key={index}
          text={question.label}
          position={question.position}
          avatar={state.avatar}
        />
      );
    case 'LOCATION':
      return (
        <Bubble

          dd={index}
          key={index}
          text={question.label}
          position={question.position}
          avatar={state.avatar}
        />
      );
    case 'NUMBER':
      return (
        <Bubble
          key={index}
          text={question.label}
          position={question.position}
          avatar={state.avatar}
        />
      );
    case 'BUTTON':
      return (
        <SingleSelect
          key={index}
          text={question.label}
          buttons={question.options}
          avatar={state.avatar}
        />
      );
    case 'MULTI_SELECT':
      return (
        <MultiSelect
          key={index}
          text={question.label}
          buttons={question.options}
          avatar={state.avatar}
        />
      );
    case 'RATING':
      return (
        <Rating
          key={index}
          text={question.label}
          buttons={question.options.map(o => o.value)}
          avatar={state.avatar}
        />
      );
    case 'RANGE':
      return (
        <RangeSlider
          key={index}
          text={question.label}
          max={parseInt(String(question.maxRange), 10)}
          min={parseInt(String(question.minRange), 10)}
          pre={question.rangePrefix}
          post={question.rangeSuffix}
          step={parseInt(String(question.rangeStep), 10)}
          avatar={state.avatar}
        />
      );
    case 'FILE':
      return (
        <File
          key={index}
          text={question.label}
          avatar={state.avatar}
        />
      );
    case 'IMAGE':
      return (
        <ImageCard
          key={index}
          text={question.label}
          image={question.source}
          avatar={state.avatar}
        />
      );
    case 'DATE':
      return (
        <DateTime
          key={index}
          text={question.label}
          avatar={state.avatar}
          time={question.showTime}
          availableDays={[0, 1, 2, 3, 4, 5, 6]}
        />
      );
    case 'CONTACT':
      return (
        <Contact
          key={index}
          text={question.label}
          buttons={question.links}
          avatar={state.avatar}
        />
      );
    case 'VIDEO':
      return <Video
        key={index}
        text={question.label}
        url={question.source}
        avatar={state.avatar}
      />;
    case 'APPOINTMENT':
      return <DateTime
        text={question.label} avatar={state.avatar}
        min={new Date()}
        max={new Date(new Date().setMonth(new Date().getMonth() + 2))}
        availableDays={question.appointment.availableDays.map(d => days[d])}
        startTime={question.appointment.startTime}
        endTime={question.appointment.endTime}
        slotInteval={intervals[question.appointment.interval] || 'half'}
        slots={true}
        key={index}
      />;
    case 'LIVE_CHAT':
      return (
        <Bubble
          key={index}
          text={question.label}
          position={question.position}
          avatar={state.avatar}
        />
      );
    case 'NEW_NOTIFICATION':
      return (
        <Comment
          key={index}
          text={question.label}
        />
      );
    case 'LIVE_CHAT_NOTIFICATION':
      return (
        <PushNotification
          key={index}
          text={question.text}
          title={question.title}
          color={question.color}
        />
      );
    case 'SMART_QUESTION':
      return (
        <Bubble
          key={index}
          text={question.label}
          position={question.position}
          avatar={state.avatar}
        />
      );

    case 'AI':
      return (
        <ModifiedBubble
          key={index}
          text={question.label}
          position={question.position}
          avatar={state.avatar}
          shouldFeedbackVisible={question.shouldFeedbackVisible}
          mid={question.mid}
          hasClickedThumbsUp={question.hasClickedThumbsUp}
          hasClickedThumbsDown={question.hasClickedThumbsDown}
        />
      );

    default:
      return null;
  }
}

export default renderComponent;
