import { Network } from './network';
import { STORE } from '../store';
import { updateSingleMessage } from '../actions/message.action';
import CryptoJS from 'crypto-js';
const network = new Network();

const encryptPayload = (payload) => {
  const newPayload = JSON.stringify(payload);

  return CryptoJS
    .AES
    .encrypt(newPayload, 'user-payload')
    .toString();
};
const saveResponse = async (payload) => new Promise(async (resolve) => {
  const { environment } = STORE.getState();
  resolve();
  if (!environment.preview) {
    if (!localStorage.getItem('__ShopifyBotUser')) {
      localStorage.setItem('__ShopifyBotUser', environment._user);
      localStorage.setItem('__ShopifyBot', environment._id);
    }
    await network.post('save-response', {
      binary: encryptPayload({
        payload,
        _bot: environment._id,
        _user: environment._user,
        uuid: environment.uuid,
        profile: environment.profile,
        isLiveChat: localStorage.getItem('agentId') ? true : false,
        agentId: localStorage.getItem('agentId') || environment._user,
      })
    });
  }
});


const updateUUID = async (payload) => new Promise(async (resolve) => {
  const { environment, meta } = STORE.getState();
  resolve();
  if (!environment.preview) {
    await network.put('update-meta', {
      binary: encryptPayload({
        payload: {
          ...meta,
          ...payload
        },
        _bot: environment._id,
        _user: environment._user,
        uuid: environment.uuid,
        profile: environment.profile
      })
    });
  }
});

const addInstallation = async () => new Promise(async (resolve) => {
  new Promise(async (resolve) => {
    const { environment } = STORE.getState();
    resolve();
    if (!environment.preview) {
      const installations = environment.installations || [];
      var baseUrl = window.location.origin;
      if (!installations.find(data => data.siteUrl === baseUrl)) {
        const network = new Network();
        await network.post('bot-installation', {
          binary: encryptPayload({
            botId: localStorage.getItem('__ShopifyBot'),
            _user: localStorage.getItem('__ShopifyBotUser'),
            siteUrl: baseUrl
          })
        });
      }
    }
  });
});

const saveMessageFeedback = async (payload) => new Promise(async (resolve, reject) => {
  const { environment, messages } = STORE.getState();
  resolve();

  if (!environment.preview) {
    await network.put('save-feedback', {
      binary: encryptPayload({
        payload: {
          ...payload
        },
        _bot: environment._id,
        _user: environment._user,
        uuid: environment.uuid
      })
    });

    const message = messages.find(message => message.mid === payload.mid);
    STORE.dispatch(updateSingleMessage({ ...message, ...payload }));
  }
})

const actionBasedOnChatResponse = async (
  activeQuestion, text, _userWebsite, _customer
) => new Promise(async (resolve, reject) => {
  try {

    if (activeQuestion?.type === 'button') {
      const selectedOption = activeQuestion?.options?.find(option => option.value === text);
      const payload = encryptPayload({
        tagIds: selectedOption?.actionBasedChatResponse?.tagIds ?? [],
        status: selectedOption?.actionBasedChatResponse?.status ?? 'OPEN',
        agentId: selectedOption?.actionBasedChatResponse?.agentId ?? undefined,
        isSubscriber: selectedOption?.actionBasedChatResponse?.isSubscriber ?? true,
        searchQuery: { _userWebsite, _customer }
      })

      await network.put(`chat-response-actions`, { binary: payload });
    }
    resolve(true);
  } catch (err) {
    console.log(JSON.stringify({ 'Error in actionBasedOnChatResponse': err }));
    reject(err);
  }
});

export { saveResponse, updateUUID, addInstallation, saveMessageFeedback, encryptPayload , actionBasedOnChatResponse };
