import * as io from 'socket.io-client';
import { STORE } from '../store';
import { newMessage, newNotification, liveChatNotification } from '../actions/message.action';
import { scroll } from './shared';
import { updateEnvironment } from '../actions/environment.action';
import { updateTextInput } from '../actions/text.action';
import { clearRequestTimer, expireLiveChatRequest, renderNextQuestion, transferToBot } from '../utilities/live-chat';

let socket;
let expireTransferChatTimer;
const isRevisit = localStorage.getItem('revisited');
const state = STORE.getState();
let typingDebounceTimer;

const makeSocketConnection = (meta) => {
  //TODO: change url to live One
  socket = io(`${process.env.REACT_APP_API_URL}/`, {
    path: '/ws/chatbot', query: {
      host: window.location.hostname,
      url: window.location.href,
      ...meta
    },
    reconnectionAttempts: 15,
    reconnectionDelayMax: 5000
  });

  socket.on('connected', () => {
    socket.emit('update-user-details', {
      configuration: {
        ...STORE.getState().configuration,
        triggerSettings: undefined,
        userAccessSettings: undefined,
        seoSettings: undefined,
        name: STORE.getState().design.name
      },
      isRevisit: !!isRevisit
    });
    localStorage.setItem('revisited', 'bp');
    localStorage.setItem('socketDisconnected', false);
    STORE.dispatch(updateEnvironment({
      socketDisconnected: false,
    }));
  });
  socket.on('error', console.log);
  socket.on('message', (message) => {
    STORE.dispatch(newMessage({
      label: message.text,
      position: 'left',
      type: 'STATEMENT'
    }));
    scroll(100);
  });
  socket.on('disconnect', function () {
    console.log('socket is disconnected');
    STORE.dispatch(updateEnvironment({
      socketDisconnected: true,
    }));
    if (localStorage.getItem('agentId')) {
      transferToBot();
    }
  });

  socket.on('chat-request-accepted', (payload) => {
    localStorage.setItem('agentId', payload.agentId);
    if (payload.whatsappNumber && payload.whatsappNumber.number && payload.whatsappNumber.prefix) {
      localStorage.setItem('bp-wsn', `+${payload.whatsappNumber.prefix}${payload.whatsappNumber.number}`);
    }
    clearRequestTimer();
    if (expireTransferChatTimer) {
      clearTimeout(expireTransferChatTimer);
    }
    STORE.dispatch(updateEnvironment({
      chatRequestRejected: false,
      disableStartButton: false,
      chatRequestAccepted: true,
      agentId: payload.agentId,
      liveChat: true,
    }));
    STORE.dispatch(updateTextInput({
      status: true,
    }));
    STORE.dispatch(newNotification({
      label: 'Your chat request has been accepted',
      type: 'NEW_NOTIFICATION'
    }));
    scroll(100);
  })

  socket.on('no-agent-found', (payload) => {
    if (payload.uuid === state.environment.uuid) {
      clearRequestTimer();
      expireLiveChatRequest(state.environment.uuid);
      STORE.dispatch(liveChatNotification({
        title: 'Live chat is Unavailable!!',
        text: state.configuration.generalSettings.unavailabilityMessage,
        color: 'orange',
        type: 'LIVE_CHAT_NOTIFICATION'
      }));
      STORE.dispatch(updateEnvironment({
        liveChatExpired: true,
        chatRequestRejected: true,
        chatRequestAccepted: false,
        noAgentFound: true,
        liveChat: false,
      }));
      renderNextQuestion()
    }
  })

  socket.on('chat-transfer-initiated', (payload) => {
    STORE.dispatch(liveChatNotification({
      title: 'Initiating Live Chat...',
      text: state.configuration.generalSettings.waitingMessage,
      color: 'blue',
      type: 'LIVE_CHAT_NOTIFICATION'
    }));
    STORE.dispatch(updateTextInput({
      status: false,
      value: ''
    }));
    expireTransferChatTimer = setTimeout(() => {
      expireLiveChatRequest(state.environment.uuid);
      STORE.dispatch(liveChatNotification({
        title: 'Live chat is Unavailable!!',
        text: state.configuration.generalSettings.unavailabilityMessage,
        color: 'orange',
        type: 'LIVE_CHAT_NOTIFICATION'
      }));
    }, 120000);
    scroll();
  })

  socket.on('typing-start', (payload) => {
    if (localStorage.getItem('agentId') && payload === state.environment.uuid) {
      STORE.dispatch(updateEnvironment({
        typing: true,
      }));
      scroll(100);
      setTimeout(() => {
        STORE.dispatch(updateEnvironment({
          typing: false,
        }));
      }, 3000)
    }
  })

  socket.on('typing-stop', (payload) => {
    if (localStorage.getItem('agentId') && payload === state.environment.uuid) {
      STORE.dispatch(updateEnvironment({
        typing: false,
      }));
    }
  })

  socket.on('chat-transferred', (payload) => {
    if (payload.mode === 'BOT') {
      STORE.dispatch(updateEnvironment({
        liveChat: false,
        agentId: undefined,
        chatRequestAccepted: false,
        disableStartButton: false,
      }));
      localStorage.removeItem('agentId');
      localStorage.removeItem('bp-wsn');
      renderNextQuestion();
    } else if (payload.mode === 'WHATSAPP') {
      STORE.dispatch(updateEnvironment({
        liveChat: false,
        agentId: undefined,
        chatRequestAccepted: false,
        disableStartButton: false,
      }));
      STORE.dispatch(newNotification({
        label: 'Your chat has been transferred to WhatsApp',
        type: 'NEW_NOTIFICATION'
      }));
      STORE.dispatch(updateTextInput({
        status: false,
        value: ''
      }));
      localStorage.removeItem('agentId');
      localStorage.removeItem('bp-wsn');
      scroll(100);
    }

  })
};



const emit = (event, payload) => {
  if (socket && socket.connected) {
    socket.emit(event, payload);
  }
};


const updateMeta = () => {
  socket.emit('update-user-details', {
    configuration: {
      ...STORE.getState().configuration,
      triggerSettings: undefined,
      userAccessSettings: undefined,
      seoSettings: undefined,
      name: STORE.getState().design.name
    },
    isRevisit: !!isRevisit
  });
};

/** Not needed as advance message does the same thing */
// const userTyping = (uuid,method) =>{
//   if(state.environment.agentId){
//     socket.emit( 'user-typing', {
//       uuid,
//       method,
//       agent:state.environment.agentId
//     })
//   }
// }

const advanceMessage = (payload) => {
  if (typingDebounceTimer) {
    clearTimeout(typingDebounceTimer);
  } else {
    // socket.emit( 'adv-message', {
    //   uuid: payload.uuid,
    //   advanceMessage: payload.advanceMessage,
    //   agent:localStorage.getItem( 'agentId')
    // })
  }
  typingDebounceTimer = setTimeout(() => {
    if (localStorage.getItem('agentId')) {
      socket.emit('adv-message', {
        uuid: payload.uuid,
        advanceMessage: payload.advanceMessage,
        agent: localStorage.getItem('agentId')
      })
    }
  }, 500);


}


export { makeSocketConnection, emit, updateMeta, advanceMessage };
