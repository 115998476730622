import React from 'react';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import Header from '../components/Header';
import Typing from '../components/Typing';
import SkipBack from '../components/SkipBack';
import TextField from '../components/TextInput';
import renderComponent from '../utilities/render-component';
import { STORE } from '../store';
import { loadFlow } from '../actions/flow.action';
import { getAttributesList, getDeviceDetails, getDynamicFont, getIpDetails, scroll } from '../utilities';
import { updateMeta } from '../actions/meta.action';
import { renderQuestion } from '../utilities/question';
import { updateUUID, addInstallation } from '../utilities/save-message';
import { makeSocketConnection, updateMeta as updateMetaToSocket } from '../utilities/socket';
import { updateTextInput } from '../actions/text.action';
import { updateAI, updateAIKey } from '../actions/ai';
import { updateEnvironment } from '../actions/environment.action';

const styles = () => ({
  chatArea: {
    marginTop: 65,
    overflowY: 'auto',
    background: 'white;',
    marginBottom: 86,
    position: 'relative'
  },
  video: {
    width: 'auto !important',
    margin: 10
  }
});

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: window.location.pathname,
      start: false,
      theme: createMuiTheme({
        typography: {
          fontFamily: ['custom-font'],
          fontWeightRegular: '600',
          fontWeightLight: '600'
        },
        palette: {
          primary: {
            main: '#cc1d38'
          },
          secondary: {
            main: '#000000'
          },
          text: {
            primary: '#ffffff'
          }
        },

      })
    };
    window.chatAreaRef = React.createRef();
  }

  componentDidMount() {

    // Check for dynamic font
    if (this.props?.design?.fontFamilyLink) {
      getDynamicFont(this.props?.design?.fontFamilyLink);
    }

    // Logic to auto scroll when launcher icon change display block from none
    let frame = window.parent.document.getElementById('ShopifyBot-messenger');
    var observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutationRecord) {
        scroll()
      });
    });
    if (frame) {
      observer.observe(frame, { attributes: true, attributeFilter: ['style'] });
    }

    localStorage.setItem('questions', JSON.stringify([]));
    window.addEventListener('message', (event) => {
      if (event.data.origin === 'ShopifyBot') {
        if (event.data.action === 'start') {
          if (!this.props.messages.length) {
            if (!this.state.start) {
              this.setState({
                start: true
              });
              renderQuestion();
              updateMetaToSocket();
              addInstallation();
            }
          }
        }
      }
    });

    if (localStorage.getItem('ShopifyBot_User_uuid') && window.parent.ShopifyBotData?.uuid !== localStorage.getItem('ShopifyBot_User_uuid')) {
      STORE.dispatch(updateEnvironment({
        ...STORE.getState().environment,
        uuid: localStorage.getItem('ShopifyBot_User_uuid')
      }));
    }

    if (window.parent.ShopifyBotData?.ai?.chatGPT?.key) {
      STORE.dispatch(updateAIKey());
    }

    STORE.dispatch(loadFlow());
    if (this.props.environment.isLanding) {
      this.setState({
        start: true
      });
      renderQuestion();
    }
    this.setState({
      theme: {
        ...this.state.theme,
        palette: {
          ...this.state.theme.palette,
          primary: {
            main: this.props.design.themeColor
          },
          text: {
            ...this.state.theme.palette.text,
            primary: this.props.design.textColor
          }
        }
      }
    });

    const root = document.documentElement;
    root.style.setProperty('--primary-theme-color', this.props.design.themeColor);
    getIpDetails()
      .then((result) => {
        this.props.updateMeta({
          ...result,
          ...getDeviceDetails(),
          location: result.city
        });
        if (!this.props.environment.preview) {
          makeSocketConnection({
            ...result,
            ...getDeviceDetails(),
            location: result.city,
            user: this.props.environment._user,
            uuid: this.props.environment.uuid
          });
        }
        updateUUID({
          ...result,
          ...getDeviceDetails(),
          location: result.city,
          url: window.location.href
        }).then().catch();
        window.parent.postMessage({
          origin: 'ShopifyBot',
          action: 'rendered'
        }, '*');
      });


    const state = STORE.getState();
    if (state.environment.liveChat) {
      STORE.dispatch(updateTextInput({
        status: true,
      }));
    }

    getAttributesList(state.environment._user)
      .then((attributesList) => {

        STORE.dispatch(updateEnvironment({
          customAttributesList: attributesList
        }));

      }).catch((error) => {
        console.log(`error in getAttributesList ${error}`);
      })
  }




  render() {
    scroll();
    const { classes } = this.props;
    const isWhitelabeled = this.props.environment.whitelabel?.active || this.props.environment.whitelabel?.byAgency;

    return (
      <ThemeProvider theme={this.state.theme}>
        {this.props.environment.isLanding ? null : <Header />}
        <div ref={window.chatAreaRef} id="chatArea" className={classes.chatArea} style={{
          background: this.props.design.backgroundColor,
          overflow: this.props.environment.dateTime ? 'hidden' : 'auto',
          marginTop: this.props.environment.isLanding ? '0' : '65px',
          height: this.props.environment.isLanding ? 'calc(100vh - 95px)'
            : isWhitelabeled
              ? 'calc(100vh - 122px)'
              : 'calc(100vh - 151px)'
        }}>
          {this.props.messages.map((message, index) => renderComponent(message, index))
          }
          {this.props.environment.typing ? <Typing avatar={this.props.design.avatar} /> : null}
          <SkipBack />
        </div>
        <TextField />
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  design: state.design,
  messages: state.messages,
  environment: state.environment
});

export default connect(mapStateToProps,
  {
    updateMeta: updateMeta
  })
  (withStyles(styles)(Index));
