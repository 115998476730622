import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import whatsapp from '../../assets/icons/whatsapp.png';
import botface from '../../assets/icons/bot-face.png';
import refresh from '../../assets/icons/refresh-icon.png';
import usertie from '../../assets/icons/user-tie.png'
import { STORE } from '../../store';
import { getAgencyLogo } from '../../utilities';
const useStyles = makeStyles({
  root: {
    backgroundColor: "transparent"
  },
  list: {
    margin: 0,
    padding: 0,
    width: '100%',
    height: '50px',
  },
  dialog: {
    position: 'absolute',
    top: 0,
    right: 0,
    maxWidth: '230px',
    height: '150px',
    overflow: 'hidden',
    marginRight: '15vh',
    marginTop: '55px',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '10px',
    opacity: 1
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  text: {
    color: '#000',

  },
  images: {
    width: '20px',
    height: '20px'
  },
  listItem: {
    height: '100%',
    marginRight: '14px',
    paddingRight: '14px'
  },
  listItemIcon: {
    marginRight: '-25px',
  },
  listItemDisabled: {
    opacity: 0.3
  }


});


function SimpleDialog(props) {
  const state = STORE.getState();
  let configurationData = state.configuration.chatMenu;
  const agencyLogo = getAgencyLogo(state?.environment?.agencyData?.meta);
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;
  let disableWhatsappBtn;
  if (localStorage.getItem('agentId') && localStorage.getItem('bp-wsn')) {
    disableWhatsappBtn = false
  } else {
    disableWhatsappBtn = true;
  }
  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog
      onClose={handleClose} aria-labelledby="simple-dialog-title"
      open={open}
      BackdropProps={{
        classes: {
          root: classes.root
        }
      }
      }
      classes={{
        paper: classes.dialog
      }}
    >
      <List className={classes.list} component="nav" aria-label="main mailbox folders" >
        <ListItem button
          className={classes.listItem}
          disabled={disableWhatsappBtn || !configurationData.toWhatsapp}
          classes={{ disabled: classes.listItemDisabled }}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <img src={whatsapp} className={classes.images} alt="whatsapp-icon" />
          </ListItemIcon>
          <ListItemText
            primary={configurationData.transferToWhatsAppText || "Transfer to WhatsApp"}
            className={classes.text}
            onClick={() => {
              props.transferToWhatsapp();
              props.onClose();
            }}
          />
        </ListItem>
        <Divider />
        {state.environment.chatRequestAccepted === false ?
          <>
            <ListItem button
              className={classes.listItem}
              disabled={state.environment.disableStartButton || !configurationData.toLiveChat}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <img src={usertie} className={classes.images} alt="agent-icon" />
              </ListItemIcon>
              <ListItemText
                primary={configurationData.startLiveChatText || "Start live chat"}
                className={classes.text}
                onClick={() => {
                  props.startLiveChat();
                  props.onClose();
                }}
              />
            </ListItem>
          </>
          :
          <>
            <ListItem button
              className={classes.listItem}
              disabled={!configurationData.toChatBot}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <img src={agencyLogo} className={classes.images} alt="bot-icon" />
              </ListItemIcon>
              <ListItemText
                primary={configurationData.transferToBotText || "Transfer To Bot"}
                className={classes.text}
                onClick={() => {
                  props.transferToBot();
                  props.onClose();
                }}
              />
            </ListItem>
          </>
        }

        <Divider />
        <ListItem button
          disabled={!configurationData.refreshChat}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <img src={refresh} className={classes.images} alt="refresh-icon" />
          </ListItemIcon>
          <ListItemText
            primary={configurationData.refreshChatText || "Refresh Chat"}
            className={classes.text}
            onClick={() => {
              props.restartChat();
              props.onClose();
            }}
          />
        </ListItem>
      </List>

    </Dialog>
  );
}

export default function MoreDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <MoreVertIcon
        alt="More Options"
        onClick={handleClickOpen}
      />
      <SimpleDialog
        open={open}
        onClose={handleClose}
        restartChat={props.restartChat}
        startLiveChat={props.startLiveChat}
        transferToBot={props.transferToBot}
        transferToWhatsapp={props.transferToWhatsapp}
        trigger={props.trigger} />
    </>
  );
}
