import { STORE } from '../store';
import { updateTextInput } from '../actions/text.action';
import { updateEnvironment } from '../actions/environment.action';
import { newMessage, updateMessage } from '../actions/message.action';
import { focus, scroll, sleep } from './shared';
import { renderQuestion } from './question';
import validator from 'validator';
import { saveResponse, updateUUID, actionBasedOnChatResponse } from './save-message';
import { emit } from './socket';
import { Network } from './network';
import { queryAIHandler } from './ai';
import { updateAIThreadId } from '../actions/ai';
import { v4 as uuid } from 'uuid';
import { setAttributeValue } from './question';

const validateQuestions = ['EMAIL', 'PHONE', 'NUMBER', 'NAME', 'APPOINTMENT', 'SMART_QUESTION'];
const excludeAnswers = ['SKIP', 'BACK'];

async function submitAnswer(answer, original = '', next = null) {
  let aiResult = {};
  const state = STORE.getState();
  let activeQuestion = state.environment.activeQuestion;
  if (!answer) {
    return;
  }

  const mid = uuid();
  if (localStorage.getItem('agentId')) {
    activeQuestion = null
  }

  emit('message', {
    text: answer,
    messageBy: 'user'
  });
  STORE.dispatch(newMessage({
    label: answer,
    position: 'right',
    type: 'STATEMENT',
    mid,
    questionId: activeQuestion?.id,
    isValid: (activeQuestion?.type === 'name') ||
      (activeQuestion?.type === 'email' && validator.isEmail(answer.trim())) ||
      (activeQuestion?.type === 'phone' && isPhoneValid(activeQuestion, answer))
  }));
  saveResponse({
    type: 'message',
    text: answer,
    messagedBy: 'user',
    questionId: activeQuestion?.id,
    shouldNotify: activeQuestion?.sendNotifications || false,
    attributeId: activeQuestion?._attribute || '',
    mid
  }).then().catch();
  scroll();

  // Action based on chat response
  actionBasedOnChatResponse(activeQuestion, answer, state.environment._chatWindowUser, state.environment._user)
    .catch(error => console.log(error));

  STORE.dispatch(updateMessage({}));
  STORE.dispatch(updateTextInput({
    status: state.environment.liveChat || state.environment.enableTextInput || state.text.status,
    value: ''
  }));

  if (!activeQuestion) {
    return;
  }

  setAttributeValue(activeQuestion, answer, state.customAttributesList);

  if (validateQuestions.includes(activeQuestion.type.toUpperCase()) && !excludeAnswers.includes(answer.toUpperCase())) {
    STORE.dispatch(updateTextInput({
      value: ''
    }));
    await sleep(0.5);
    const messageUuid = uuid();
    switch (activeQuestion.type.toUpperCase()) {
      case 'EMAIL': {
        /**
         * Logic for Whether to Include or Exclude the domains in selecteddomainsList[] 
         */
        const questionData = activeQuestion.message.configureDomains;
        let query;
        (questionData?.domainAction === 'include' && questionData?.selectedDomainsList?.length)
          ? query = (!(questionData?.selectedDomainsList || []).includes(answer.substr(answer.lastIndexOf('@') + 1).trim()))
          : query = ((questionData?.selectedDomainsList || []).includes(answer.substr(answer.lastIndexOf('@') + 1).trim()));

        if (query && validator.isEmail(answer.trim())) {
          STORE.dispatch(newMessage({
            label: questionData.domainErrorMessage || 'This domain is not acceptable',
            position: 'left',
            type: 'STATEMENT',
            mid: messageUuid
          }));
          emit('message', {
            text: questionData.domainErrorMessage || 'This domain is not acceptable',
            messageBy: 'bot'
          });
          saveResponse({
            type: 'message',
            text: questionData.domainErrorMessage || 'This domain is not acceptable',
            messagedBy: 'bot',
            mid: messageUuid
          }).then().catch();
          scroll();
          focus();
          return;
        }
        if (validator.isEmail(answer.trim())) {
          updateUUID({
            email: answer,
            lead: true
          }).then().catch();
          STORE.dispatch(updateEnvironment({
            response: {
              ...state.environment.response,
              email: answer
            }
          }));
          sessionStorage.setItem('attendeeEmail', answer);
          break;
        }

        /**
         * Author:Aman
         */

        STORE.dispatch(newMessage({
          label: activeQuestion.message.error,
          position: 'left',
          type: 'STATEMENT',
          mid: messageUuid
        }));
        emit('message', {
          text: activeQuestion.message.error,
          messageBy: 'bot'
        });
        saveResponse({
          type: 'message',
          text: activeQuestion.message.error,
          messagedBy: 'bot',
          mid: messageUuid
        }).then().catch();
        scroll();
        focus();
        return;
      }
      case 'PHONE': {
        if (isPhoneValid(activeQuestion, answer)) {
          updateUUID({
            phone: answer,
            lead: true
          }).then().catch();
          STORE.dispatch(updateEnvironment({
            response: {
              ...state.environment.response,
              phone: answer
            }
          }));
          break;
        }
        emit('message', {
          text: activeQuestion.message.error,
          messageBy: 'bot'
        });
        STORE.dispatch(newMessage({
          label: activeQuestion.message.error,
          position: 'left',
          type: 'STATEMENT',
          mid: messageUuid
        }));
        saveResponse({
          type: 'message',
          text: activeQuestion.message.error,
          messagedBy: 'bot',
          mid: messageUuid
        }).then().catch();
        scroll();
        focus();
        return;
      }
      case 'NUMBER': {
        if (validator.isNumeric(answer.trim())) {
          break;
        }
        emit('message', {
          text: activeQuestion.message.error,
          messageBy: 'bot'
        });
        STORE.dispatch(newMessage({
          label: 'Answer must be numeric!',
          position: 'left',
          type: 'STATEMENT',
          mid: messageUuid
        }));
        saveResponse({
          type: 'message',
          text: 'Answer must be numeric!',
          messagedBy: 'bot',
          mid: messageUuid
        }).then().catch();
        scroll();
        focus();
        return;
      }
      case 'NAME': {
        if (answer.trim().length >= activeQuestion.maxRange) {
          emit('message', {
            text: activeQuestion.message.error,
            messageBy: 'bot'
          });
          STORE.dispatch(newMessage({
            label: activeQuestion.message.error,
            position: 'left',
            type: 'STATEMENT',
            mid: messageUuid
          }));
          saveResponse({
            type: 'message',
            text: activeQuestion.message.error,
            messagedBy: 'bot',
            mid: messageUuid
          }).then().catch();
          STORE.dispatch(updateTextInput({
            status: true,
            value: ''
          }));
          scroll();
          focus();
          return;
        }
        updateUUID({
          name: answer
        }).then().catch();
        STORE.dispatch(updateEnvironment({
          response: {
            ...state.environment.response,
            name: answer
          }
        }));
        sessionStorage.setItem('name', answer);
        break;
      }
      case 'APPOINTMENT': {
        if (!state.environment.preview && sessionStorage.getItem('attendeeEmail') && validator.isEmail(sessionStorage.getItem('attendeeEmail'))) {
          const network = new Network();
          await network.post('process-appointment', {
            email: sessionStorage.getItem('attendeeEmail'),
            _user: state.environment._user,
            _bot: state.environment._id,
            start: new Date(answer).toISOString(),
            end: new Date(new Date(answer).setMinutes(new Date(answer).getMinutes() + activeQuestion.appointment.interval)).toISOString()
          });
        }
        break;
      }
      case 'SMART_QUESTION': {
        let found = false;
        let options = activeQuestion.options;
        options = options.sort((a, b) => (a.keywordType.toLowerCase() === 'contain') ? 1 : -1);
        let index;

        options.map(option => {
          if (option.keywordType.toLowerCase() === 'exact' && option.smartKeywords.includes(answer)) {
            found = true;

            if (option.next.target === 'end') {
              STORE.dispatch(updateTextInput({
                status: false
              }));
              return false;
            }

            index = state.flows[0].questions.findIndex(q => q.id === option.next.target);
            renderQuestion(index);
            scroll();
            focus();
            return;
          } else if (option.keywordType.toLowerCase() === 'contain') {
            option.smartKeywords.map(keyword => {
              if (answer.toLowerCase().includes(keyword.toLowerCase()) && !found) {
                found = true;

                if (option.next.target === 'end') {
                  STORE.dispatch(updateTextInput({
                    status: false
                  }));
                  return false;
                }

                index = state.flows[0].questions.findIndex(q => q.id === option.next.target);
                renderQuestion(index);
                return;
              }
            })
          }
        })
        if (found) {
          return;
        }
        break;
      }
      default: {
        scroll();
        focus();
        return;
      }
    }
  }
  STORE.dispatch(updateEnvironment({
    refresh: state.environment.liveChat,
    lastQuestion: activeQuestion?.id,
    skip: false,
    back: false
  }));


  if (activeQuestion?.includeInLeads) {
    updateUUID({
      lead: true,
      customParameters: {
        [activeQuestion.label]: answer
      }
    }).then().catch();
  }


  scroll();
  focus();
  let index;
  if (next) {
    index = state.flows[0].questions.findIndex(q => q.id === next);
    if (index === -1) {
      index = state.flows[0].questions.findIndex(q => q.id === activeQuestion?.id);
      index = index + 1;
    }
  } else {
    if (activeQuestion.next.target) {
      index = state.flows[0].questions.findIndex(q => q.id === activeQuestion.next.target);
      if (index === -1) {
        index = state.flows[0].questions.findIndex(q => q.id === activeQuestion?.id);
        index = index + 1;
      }
    } else {
      index = state.flows[0].questions.findIndex(q => q.id === activeQuestion?.id);
      index = index + 1;
    }
  }
  const constants = ['STATEMENT', 'IMAGE', 'CONTACT', 'VIDEO'];
  if (!constants.includes(activeQuestion.label.toUpperCase())) {
    if (!localStorage.getItem('questions')) {
      localStorage.setItem('questions', JSON.stringify([]));
    }
    if (localStorage.getItem('questions')) {
      const qs = JSON.parse(localStorage.getItem('questions'));
      localStorage.setItem('questions', JSON.stringify([...qs, {
        question: activeQuestion.label,
        questionId: activeQuestion?.id,
        answer
      }]));
      emit('update-user-details', {
        messages: [...qs, {
          question: activeQuestion.label,
          questionId: activeQuestion?.id,
          answer
        }]
      });
    }
  }

  if (activeQuestion.type.toUpperCase() === 'AI') {
    STORE.dispatch(updateTextInput({
      status: false,
      value: ''
    }));

    STORE.dispatch(updateEnvironment({
      typing: true
    }));
  } else {
    STORE.dispatch(updateTextInput({
      status: true,
      value: ''
    }));
  }

  if (activeQuestion.next.target === 'end' || next === 'end') {
    STORE.dispatch(updateTextInput({
      status: false
    }));
  }


  if (activeQuestion.type.toUpperCase() === 'AI') {
    aiResult = await queryAIHandler(
      { 
        query: answer, _bot: state.environment._id, hash: 'upbu8kseWBhofnorUuICXTmX5',
        ...(state.ai?.threadId && { threadId: state?.ai?.threadId }) 
      });

    if (aiResult?.threadId) {
      updateUUID({ threadId: aiResult.threadId })
        .then()
        .catch();

      STORE.dispatch(updateAIThreadId({
        threadId: aiResult.threadId
      }));
    }
  }

  if (!state.environment.liveChat) {
    renderQuestion(index, aiResult?.aiResponse);
  }
}

const isPhoneValid = (activeQuestion, answer) => {
  const minLength = activeQuestion?.phoneValidation?.minLength ?? 7;
  const maxLength = activeQuestion?.phoneValidation?.maxLength ?? 15;
  const isPhoneValidationEnabled = activeQuestion?.phoneValidation?.isEnabled ?? true;

  if (isPhoneValidationEnabled) {
    return validator.isMobilePhone(answer);
  }

  return (answer.length >= minLength && answer.length <= maxLength);
};

export { submitAnswer };

