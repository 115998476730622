import { UPDATE_ENVIRONMENT } from '../actions/actions';

const originalEnvironment = undefined;

let environment = sessionStorage.getItem(`ShopifyBot-${window.parent.ShopifyBotData._id}`);

if (environment) {
  try {
    environment = JSON.parse(environment).environment || originalEnvironment;
  } catch (e) {
    environment = originalEnvironment;
  }
}

const environmentReducer = (state = environment || {
  _id: window.parent.ShopifyBotData._id,
  _user: window.parent.ShopifyBotData._user,
  activeQuestion: null,
  activeFlow: window.parent.ShopifyBotData.flows[0].id,
  preview: window.parent.ShopifyBotData.preview || false,
  embedded: window.parent.ShopifyBotData.embedded || false,
  refresh: true,
  typing: false,
  pause: false,
  activeQuestionType: 'statement',
  skip: false,
  back: false,
  dateTime: false,
  uuid: window.parent.ShopifyBotData.uuid,
  lastQuestion: '',
  profile: window.parent.ShopifyBotData.profile || '',
  plan: window.parent.ShopifyBotData.plan || '',
  liveChat: false,
  isLanding: window.parent.ShopifyBotData.isLanding,
  whitelabel: window.parent.ShopifyBotData.whitelabel || {},
  chatRequestAccepted: false,
  chatRequestRejected: false,
  _chatWindowUser: window.parent.ShopifyBotData._chatWindowUser,
  agentId: undefined,
  socketDisconnected: false,
  installations: window.parent.ShopifyBotData.installations || [],
  agencyData: window.parent.ShopifyBotData.agency || {},
  chatMenu: window.parent.ShopifyBotData.configuration.chatMenu,
  _flow: window.parent.ShopifyBotData.flows[0]._id,
  customAttributesList: []
}, action) => {
  switch (action.type) {
    case UPDATE_ENVIRONMENT: {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
};

export { environmentReducer };
