import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import ImageAvatars from '../Avatar';
import { connect } from 'react-redux';
import MoreDialog from '../MoreDialog';
import { requestLivechat, transferToBot, transferToWhatsapp } from '../../utilities/live-chat';
import { STORE } from '../../store';

const useStyles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  text: {
    wordBreak: 'break-all',
    maxWidth: '100%',
  },
  icons: {
    display: 'flex',
  },
  toolbar: {
    padding: 0,
    paddingRight: 5,
    minHeight: 65
  },
  headText: {
    fontSize: 20,
    color: theme.palette.text.primary
  },
  icon: {
    color: theme.palette.text.primary
  },
  color: {
    color: theme.palette.text.primary
  },
  dNone: {
    display: 'none',

  },
  dInfo: {
    backgroundColor: 'white',
    color: '#118cfc',
    fontWeight: '700',
    textAlign: 'center',
    transition: 'opacity 1.5s',
    position: 'fixed',
    top: '65px',
    width: '100%',
    padding: '4px',
    'box-shadow': '2px 1px 2px 1px rgb(0 0 0 / 20%)',
    opacity: 0
  },
  connectivityIssue: {
    opacity: 1
  }
});

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      close: false,
      refresh: false,
      menuOpen: false,
      trigger: 'bot',
      hideInfoDiv: false,
    };
    this.socket = null;
  }
  state = STORE.getState();

  updateState = () => {
    const shopifybot = new window.parent.ShopifyBot();
    shopifybot.closeWindow();
    localStorage.setItem('bp-user-close', 'true');
  };

  restartChat = () => {
    // this.props.updateFlow();
    transferToBot(true);
    // renderQuestion( 0 );
  };

  startLiveChat = () => {
    requestLivechat();
  }

  transferToBot = () => {
    transferToBot()
  }

  transferToWhatsapp = () => {
    transferToWhatsapp();
  }

  hideInfoDiv = () => {
    this.setState({ hideInfoDiv: true })
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.grow}>
          <AppBar position="fixed" id={'header'} elevation={0}>
            <Toolbar className={classes.toolbar}>
              <ImageAvatars
                avatar={this.props.design.avatar} />
              <Typography className={classes.headText} variant="body1" noWrap>
                {this.props.design.name}
              </Typography>
              <div className={classes.grow} />
              <div className={classes.icons} style={{ color: this.props.color || 'black' }}>
                <div>
                  <IconButton className={classes.icon}
                    // disabled={!this.props.environment.refresh}
                    color="inherit">
                    <MoreDialog
                      restartChat={this.restartChat}
                      startLiveChat={this.startLiveChat}
                      liveChat={STORE.getState().environment.liveChat}
                      transferToWhatsapp={this.transferToWhatsapp}
                      transferToBot={this.transferToBot}
                    />
                  </IconButton>
                </div>

                
                {this.props.environment.embedded ? null : <IconButton
                  onClick={this.updateState}
                  disabled={this.state.close}
                  color="inherit"
                  className={classes.icon}
                >
                  <CancelIcon />
                </IconButton>}
              </div>
            </Toolbar>

            <div
              className={`${classes.dInfo} ${this.props.environment.socketDisconnected ? classes.connectivityIssue : classes.dNone}`}
              onClick={this.hideInfoDiv}
            >
              <p style={{ margin: 'auto' }}>Due to network issue, chat has been transferred back to bot! </p>
            </div>
              
          </AppBar>
        </div>

      </>
    );
  }
}

Header.defaultProps = {
  heading: 'ShopifyBot'
};

Header.proptype = {
  heading: PropTypes.element.isRequired
};

const mapStateToProps = state => ({
  design: state.design,
  environment: state.environment
});

export default connect(mapStateToProps, {})(withStyles(useStyles)(Header));
